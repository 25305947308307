// Runtime webpack config
import '../../other/WebpackConfig';

import '../../../scss/l1Page.mp.nlnl.scss';

// i18n
import i18n from '../../i18n/nl-NL';

import l1Page from './l1Page';

l1Page(i18n);
