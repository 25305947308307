import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { CategoryMenuBar } from '@hz-design-system/web-ui';
import { TL1PageClientConfig } from 'types/TL1PageClientConfig';

type TCategoryMenuBarAppProps = { appState: TL1PageClientConfig };

const CategoryMenuBarApp = ({ appState: { categoryLinks, topCategories } }: TCategoryMenuBarAppProps) => {
  const { t } = useI18nContext();

  if (!categoryLinks || !topCategories.length) {
    return null;
  }

  return (
    <CategoryMenuBar
      l1Categories={categoryLinks}
      topCategories={topCategories}
      toggleLabel={t('l1.navigation.categories.all_categories')}
    />
  );
};

export default CategoryMenuBarApp;
