import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Icon, IconButton } from '@hz-design-system/web-ui';

import Classes from './CategoryBreadcrumbs.scss';
import { TL1PageClientConfig } from 'types/TL1PageClientConfig';

type TCategoryBreadcrumbsProps = {
  l1Category: TL1PageClientConfig['l1Category'];
};

const handleBackArrowClick = () => {
  window.history.back();
};

const handleIconClick = () => {
  window.location.assign('/');
};

const CategoryBreadcrumbs = ({ l1Category: { name, id } }: TCategoryBreadcrumbsProps) => {
  const { t } = useI18nContext();

  return (
    <div className={Classes.categoryBreadcrumb}>
      <IconButton.Secondary
        name="back"
        ariaLabel={t('common:page.navigation.back')}
        className={Classes.breadcrumbBox}
        utilities={['marginRightXxs']}
        size="small"
        onClick={handleBackArrowClick}
        data-testid="history-back"
      />
      <IconButton.Secondary
        name="home"
        ariaLabel={t('common:page.navigation.home')}
        className={Classes.breadcrumbBox}
        utilities={['marginRightXxs']}
        size="small"
        onClick={handleIconClick}
        data-testid="go-home"
      />
      <div className={Classes.categoryTitle}>
        <div className={Classes.categoryIconContainer}>
          <Icon className={Classes.categoryIcon} name={id.toString()} color="brand-primary" />
        </div>
        <h1 className="page-header heading heading-1">{name}</h1>
      </div>
    </div>
  );
};

export default CategoryBreadcrumbs;
