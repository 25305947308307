const HTTP_STATUS = {
  OK: 200,
  REDIRECTION: 300,
};

type TFetchAPIParams = {
  method?: string;
  url: string;
  xsrfToken?: string;
  payload?: object;
  preloadCompatible?: boolean;
};

/**
 * Fetch API request
 *
 * Set `preloadCompatible: true` to do fetch call in mode compatible with `preload` link
 * @see https://stackoverflow.com/a/63814972/1387163
 * Important: make sure do not send extra headers or payload when `preloadCompatible: true`,
 * otherwise preloaded request won't be matched.
 */
export default async function fetchAPI({
  method = 'GET',
  url,
  xsrfToken,
  payload,
  preloadCompatible = false,
}: TFetchAPIParams) {
  const res = await fetch(url, {
    method,
    credentials: preloadCompatible ? 'include' : 'same-origin',
    mode: preloadCompatible ? 'no-cors' : 'cors',
    headers: {
      ...(payload
        ? {
            'Content-Type': 'application/json',
          }
        : {}),
      ...(xsrfToken
        ? {
            'x-mp-xsrf': xsrfToken,
          }
        : {}),
    },
    ...(payload
      ? {
          body: JSON.stringify(payload),
        }
      : {}),
  });

  if (res.status >= HTTP_STATUS.OK && res.status < HTTP_STATUS.REDIRECTION) {
    return res.json();
  }

  throw new Error(res.statusText);
}
