import React from 'react';
import { Card, Title4 } from '@hz-design-system/web-ui';

import Classes from './Categories.scss';
import CategoryList from './CategoryList';
import { TL1PageClientConfig } from 'types/TL1PageClientConfig';

type TCategoriesProps = {
  categoryBuckets: TL1PageClientConfig['categoryBuckets'];
};

const Categories = ({ categoryBuckets }: TCategoriesProps) => (
  <div className={Classes.root}>
    {categoryBuckets.map((bucket) => (
      <div key={bucket.id} className={Classes.cardContainer}>
        <Card className={Classes.card} padding="s">
          <Title4 className={Classes.title}>{bucket.name}</Title4>
          <CategoryList bucketId={bucket.id} l2Categories={bucket.categories} />
        </Card>
      </div>
    ))}
  </div>
);

export default Categories;
