import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { isAdobeDmpPurposeAccepted } from '@ecg-marktplaats/gdpr-consent-string';
import { UtilsBlock } from '@hz-design-system/web-ui';

import adobeRegistration from './utils/adobeRegistration';
import displayCookies from './constants/banners';
import fetchAPI from '../../utils/fetchApi';

import { TCategoryItemApi } from 'types/TCategories';
import { TEnvConfig } from 'types/TServerContext';

type TBannerContainerProps = {
  id: string;
  environment: TEnvConfig;
  l1Category: TCategoryItemApi;
};

let rsiSegmentsIsCalled = false;

const BannerContainer = ({ id, environment, l1Category }: TBannerContainerProps) => {
  const {
    links: { audienceTargetingUrl },
    xsrfToken,
    tenantContext: { tenant, locale },
    encryptedUserId,
    fingerPrint,
    audienceTargeting: { isEnabled, crmId, partnerId, customerOrganisationId },
  } = environment;

  useEffect(() => {
    const consent =
      isAdobeDmpPurposeAccepted() || window?._sp_lib?.getConsentState(document.cookie)?.AdobeAudienceManager;
    if (consent && isEnabled && !rsiSegmentsIsCalled) {
      // await is not applied for this function to keep the logic same as current homepage
      // it can be a experiment idea, loading banners after having the segmentation data
      adobeRegistration({ crmId, partnerId, customerOrganisationId, encryptedUserId });

      if (Cookies.get(displayCookies.ADOBE_IDENTIFIER)) {
        rsiSegmentsIsCalled = true;
        const callAudienceTargetApi = async () => {
          try {
            const rsiSegments = await fetchAPI({
              url: audienceTargetingUrl,
              method: 'POST',
              xsrfToken,
              payload: {
                device: 'web',
                encryptedUserId,
                fingerPrint,
                intent: 'search',
                l1CategoryId: l1Category.id,
                l1CategoryName: l1Category.name,
                locale,
                searchAttributes: {},
                tenant: tenant.toLocaleUpperCase(),
                uuid: Cookies.get(displayCookies.ADOBE_IDENTIFIER),
              },
            });
            window?.mpBannerLibConfig?.bannerData.map((data) => {
              const { bannerParameters } = data;
              if (rsiSegments?.segments) {
                bannerParameters.wl = rsiSegments.segments.split('|');
              }
            });
          } catch (e) {
            rsiSegmentsIsCalled = false;
          }
        };
        callAudienceTargetApi();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <UtilsBlock className={id} id={id} />;
};

export default BannerContainer;
