import React, { useEffect, useState } from 'react';
import { string, shape } from 'prop-types';
import { EnvironmentProvider } from '../../contexts/EnvironmentContext';

let Feeds;

const FeedsApp = ({ appState, environment }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { l1Category, feeds, userPostcode, initialFeed, scrollingDisabled = true } = appState;

  const loadComponent = async () => {
    try {
      Feeds = (await import(/* webpackChunkName: "feed" */ './components/Feeds/Feeds')).default;
      setIsLoaded(true);
    } catch (err) {}
  };

  useEffect(() => {
    const element = document.getElementById('feeds-root');

    if (element) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0]?.intersectionRatio > 0) {
          loadComponent();

          intersectionObserver.unobserve(element);
        }
      });

      intersectionObserver.observe(element);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <EnvironmentProvider value={environment}>
      <Feeds
        feeds={feeds}
        feedPostcode={userPostcode}
        initialFeed={initialFeed}
        l1CategoryId={l1Category.id}
        scrollingDisabled={scrollingDisabled}
      />
    </EnvironmentProvider>
  );
};

FeedsApp.propTypes = {
  appState: shape({
    foo: string,
  }),
  environment: shape({
    xsrfToken: string,
  }),
};

export default FeedsApp;
