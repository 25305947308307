import React from 'react';
import CategoryBreadcrumbs from './components/CategoryBreadcrumbs/CategoryBreadcrumbs';
import { TL1PageClientConfig } from 'types/TL1PageClientConfig';

type TCategoryBreadcrumbsProps = {
  appState: TL1PageClientConfig;
};

const CategoryBreadcrumbsApp = ({ appState }: TCategoryBreadcrumbsProps) => (
  <CategoryBreadcrumbs l1Category={appState.l1Category} />
);

export default CategoryBreadcrumbsApp;
