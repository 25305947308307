import React, { useState } from 'react';
import { Link, Icon } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import Classes from './Categories.scss';
import { TCategoryBucketsClient, TL2CategoryClient } from 'types/TCategoryBuckets';
import { trackGAEvent } from '../../../../utils/gaTracking';

type TCategoriesProps = {
  l2Categories: TCategoryBucketsClient['categories'];
  bucketId: number;
};

const maxDisplayItems = 3;

const handleClick = (l2Category: TL2CategoryClient) => {
  trackGAEvent('L2CategoryClicked', l2Category.id.toString());
};

const CategoryList = ({ l2Categories, bucketId }: TCategoriesProps) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const l1CategoriesTranslationKey = showMore ? 'l1.categories.showLess' : 'l1.categories.showMore';
  const moreLessIconName = showMore ? 'arrow-up' : 'arrow-down';

  return (
    <>
      {l2Categories.map((l2category, index) => (
        <Link
          kind="isolated"
          key={l2category.id}
          className={`${Classes.link} ${!showMore && index >= maxDisplayItems && Classes.hiddenLinks}`}
          href={l2category.url}
          onClick={() => handleClick(l2category)}
          data-testid={`navigation-link-${l2category.id}`}
        >
          {l2category.shortName}
        </Link>
      ))}
      {l2Categories.length > maxDisplayItems && (
        <div className={Classes.showMore}>
          <Link key={bucketId} onClick={handleShowMoreClick} kind="isolated">
            <Trans i18nKey={l1CategoriesTranslationKey} />
            <Icon name={moreLessIconName} color="signal-link-default" size="m" />
          </Link>
        </div>
      )}
    </>
  );
};

export default CategoryList;
