import React from 'react';
import Categories from './components/Categories/Categories';
import { TL1PageClientConfig } from 'types/TL1PageClientConfig';

type TCategoriesAppProps = {
  appState: TL1PageClientConfig;
};

const CategoriesApp = ({ appState: { categoryBuckets } }: TCategoriesAppProps) => (
  <Categories categoryBuckets={categoryBuckets} />
);

export default CategoriesApp;
