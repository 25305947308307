import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nContext } from '@ecg-marktplaats/js-react-i18n';
import { Header } from '@hz-design-system/web-ui';

import FeedsApp from '../../apps/FeedsApp';
import CategoryMenuBarApp from '../../apps/CategoryMenuBar/CategoryMenuBar';
import { trackGAEvent } from '../../utils/gaTracking';
import CategoriesApp from '../../apps/Categories/CategoriesApp';
import CategoryBreadcrumbsApp from '../../apps/CategoryBreadcrumbs/CategoryBreadcrumbsApp';
import BannersApp from '../../apps/Banners/Banners';

import '../../../scss/l1Page.scss';
// server side only components styles for this page
import '../../../../server/pages/l1Page.styles';

export default (i18n) => {
  // eslint-disable-next-line no-underscore-dangle
  const appState = window.__CONFIG__ || {
    carsCategoryLinks: [],
  };
  const environment = window.__ENV_CONFIG__ || {};
  const headerConfig = window.__HEADER_CONFIG__ || {};

  // Header is server side rendered in express-react library
  hydrateRoot(
    document.getElementById('header-root'),
    <Header headerConfig={headerConfig} trackGAEvent={trackGAEvent} />,
  );

  hydrateRoot(
    document.getElementById('banner-root'),
    <I18nContext.Provider value={i18n}>
      <BannersApp id="banner-top-dt" environment={environment} l1Category={appState.l1Category} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    document.getElementById('category-menu-bar-root'),
    <I18nContext.Provider value={i18n}>
      <CategoryMenuBarApp appState={appState} environment={environment} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    document.getElementById('feeds-root'),
    <I18nContext.Provider value={i18n}>
      <FeedsApp appState={appState} environment={environment} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    document.getElementById('categories-root'),
    <I18nContext.Provider value={i18n}>
      <CategoriesApp appState={appState} />
    </I18nContext.Provider>,
  );

  hydrateRoot(
    document.getElementById('category-breadcrumbs-root'),
    <I18nContext.Provider value={i18n}>
      <CategoryBreadcrumbsApp appState={appState} />
    </I18nContext.Provider>,
  );
};
