import { TEnvConfig } from 'types/TServerContext';

// Use full path with a domain for static resources
// This is needed to resolve paths of dynamic imports
// eslint-disable-next-line camelcase, no-undef, no-underscore-dangle
const environment: TEnvConfig = window.__ENV_CONFIG__;

__webpack_public_path__ = environment.staticBaseUrl;

export {};
